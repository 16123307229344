///
/// Formula by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Wrapper */

	#wrapper {
		$main-pad: _size(main-pad);

		> * {
			@include padding($main-pad, 0);
			border-bottom: solid 2px _palette(border);
			overflow: hidden;

			> .inner {
				margin: 0 auto;
				max-width: calc(100% - 4em);
				width: _size(inner-width);
			}
		}

		> .main.sidebar {
			> .inner {
				@include vendor('display', 'flex');
				@include vendor('flex-wrap', 'wrap');

				> header {
					width: 100%;
				}

				> .content {
					width: 75%;
					padding: 0 (_size(element-margin) * 2) 0 0;
				}

				> .sidebar {
					width: 25%;
				}
			}
		}
		@include breakpoint(small) {
			$main-pad: _size(main-pad) * 0.75;

			> * {
				@include padding($main-pad, 0);
			}
			> .main.sidebar {
				> .inner {
					@include vendor('display', 'flex');
					@include vendor('flex-wrap', 'wrap');

					> header {
						width: 100%;
					}

					> .content {
						width: 100%;
						padding: 0 (_size(element-margin) * 2) 0 0;
					}

					> .sidebar {
						width: 100%;
					}
				}
			}
		}
	}