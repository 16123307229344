///
/// Formula by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		@include vendor('appearance', 'none');
		@include vendor('transition', (
			'background-color #{_duration(transition)} ease-in-out',
			'color #{_duration(transition)} ease-in-out',
			'box-shadow #{_duration(transition)} ease-in-out',
		));
		border-radius: _size(border-radius);
		border: 0;
		cursor: pointer;
		display: inline-block;
		font-size: 0.8em;
		font-weight: _font(weight-bold);
		height: 3.5em;
		letter-spacing: _font(kerning);
		line-height: 3.5em;
		padding: 0 2em;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;

		&.icon {
			&:before {
				margin-right: 0.5em;
			}
		}

		&.fit {
			display: block;
			margin: 0 0 (_size(element-margin) * 0.5) 0;
			width: 100%;
		}

		&.small {
			font-size: 0.6em;
		}

		&.big {
			min-width: 19em;
			height: 3.75em;
			line-height: 3.75em;
		}

		&.disabled,
		&:disabled {
			@include vendor('pointer-events', 'none');
			opacity: 0.25;
		}
	}

	@mixin color-button($p: null) {
		$highlight: _palette($p, highlight);

		input[type="submit"],
		input[type="reset"],
		input[type="button"],
		button,
		.button {
			background-color: transparent;
			box-shadow: inset 0 0 0 (_size(border-width) * 2) _palette($p, border);
			color: _palette($p, fg-bold) !important;

			@if ($p == $highlight) {
				&:hover {
					color: _palette($p, fg-bold) !important;
					background-color: _palette($p, border-bg);
				}

				&:active {
					color: _palette($p, fg-bold) !important;
					background-color: _palette($p, border2-bg);
				}
			}
			@else {
				&:hover {
					box-shadow: inset 0 0 0 (_size(border-width) * 2) _palette($highlight, bg);
					color: _palette($highlight, bg) !important;
				}

				&:active {
					box-shadow: inset 0 0 0 (_size(border-width) * 2) _palette($highlight, bg);
					color: _palette($highlight, bg) !important;
					background-color: transparentize(_palette($highlight, bg), 0.75);
				}
			}

			&.special {
				box-shadow: none;

				@if ($p == $highlight) {
					background-color: _palette($p, fg-bold);
					color: _palette($p, bg) !important;

					@if ($p == 'invert') {
						&:hover {
							background-color: darken(_palette(accent1, bg), 5);
							color: _palette(accent1, fg-bold) !important;
						}

						&:active {
							background-color: darken(_palette(accent1, bg), 15);
							color: _palette(accent1, fg-bold) !important;
						}
					}
				}
				@else {
					background-color: _palette($highlight, bg);
					color: _palette($highlight, fg-bold) !important;

					&:hover {
						background-color: darken(_palette($highlight, bg), 5);
						color: _palette($highlight, fg-bold) !important;
					}

					&:active {
						background-color: darken(_palette($highlight, bg), 15);
						color: _palette($highlight, fg-bold) !important;
					}
				}
			}
		}
	}

	@include color-button;