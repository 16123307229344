///
/// Formula by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Icon */

	.icon {
		@include icon;
		border-bottom: none;
		position: relative;
		text-align: center;

		> .label {
			display: none;
		}

		&.major {
			cursor: default;
			display: block;
			margin: 0 0 (_size(element-margin) * 0.75) 0;

			&:before {
				background-color: _palette(fg-bold);
				border-radius: 100%;
				color: _palette(bg);
				display: inline-block;
				font-size: 2.5rem;
				height: 2.25em;
				line-height: 2.25em;
				text-align: center;
				width: 2.25em;
			}

			&.style1 {
				&:before {
					background-color: _palette(accent1, bg);
					color: _palette(accent1, fg-bold);
				}
			}

			&.style2 {
				&:before {
					background-color: _palette(accent2, bg);
					color: _palette(accent2, fg-bold);
				}
			}

			&.style3 {
				&:before {
					background-color: _palette(accent3, bg);
					color: _palette(accent3, fg-bold);
				}
			}

			&.style4 {
				&:before {
					background-color: _palette(accent4, bg);
					color: _palette(accent4, fg-bold);
				}
			}
		}

		&.alt {
			&:before {
				@include vendor('transition', 'background-color #{_duration(transition)} ease-in-out, color #{_duration(transition)} ease-in-out');
				background-color: _palette(accent1, bg);
				border-radius: 100%;
				color: _palette(accent1, fg-bold);
				display: inline-block;
				font-size: 1.5rem;
				height: 2.25em;
				line-height: 2.25em;
				text-align: center;
				width: 2.25em;
			}

			&:hover {
				&:before {
					background-color: darken(_palette(accent1, bg), 5);
				}
			}

			&:active {
				&:before {
					background-color: darken(_palette(accent1, bg), 15);
				}
			}

			@mixin icon-alt($x, $y) {
				&.#{$x} {
					&:before {
						background-color: _palette($y);
					}

					&:hover {
						&:before {
							background-color: darken(_palette($y), 5);
						}
					}

					&:active {
						&:before {
							background-color: darken(_palette($y), 15);
						}
					}
				}
			}

			@include icon-alt(fa-twitter, twitter);
			@include icon-alt(fa-facebook, facebook);
			@include icon-alt(fa-youtube, youtube);
			@include icon-alt(fa-instagram, instagram);

			@include breakpoint(small) {
				&:before {
					font-size: 1rem;
				}
			}
		}
	}