///
/// Formula by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Menu */

	#menu {
		@include vendor('transform', 'translateX(20em)');
		@include vendor('transition', ('transform #{_duration(menu)} ease', 'box-shadow #{_duration(menu)} ease', 'visibility #{_duration(menu)}'));
		-webkit-overflow-scrolling: touch;
		background: _palette(bg);
		color: _palette(fg-bold);
		height: 100%;
		max-width: 80%;
		overflow-y: auto;
		padding: 3em 2em;
		position: fixed;
		right: 0;
		top: 0;
		visibility: hidden;
		width: 20em;
		z-index: _misc(z-index-base) + 2;

		> ul {
			margin: 0 0 (_size(element-margin) * 0.5) 0;

			&.links {
				list-style: none;
				padding: 0;

				> li {
					padding: 0;

					> a:not(.button) {
						border: 0;
						border-top: solid 1px _palette(border);
						color: inherit;
						display: block;
						font-size: 0.8em;
						font-weight: _font(weight-bold);
						letter-spacing: _font(kerning);
						//line-height: 4.5em;
                        padding:1em 0;
						text-transform: uppercase;
					}

					> .button {
						display: block;
						margin: 0.5em 0 0 0;
					}

					&:first-child {
						> a:not(.button) {
							border-top: 0 !important;
						}
					}
				}
			}
		}

		.close {
			-webkit-tap-highlight-color: rgba(0,0,0,0);
			background-image: url(images/close.svg);
			background-position: 75% 50%;
			background-repeat: no-repeat;
			border: 0;
			color: _palette(accent2, fg-light);
			cursor: pointer;
			display: block;
			height: 3.25em;
			line-height: 3.25em;
			padding-right: 1.25em;
			position: absolute;
			right: 0;
			text-align: right;
			top: 0;
			vertical-align: middle;
			width: 7em;

			&:before {
				font-size: 1.25em;
			}

			@include breakpoint(small) {
				height: 4em;
				line-height: 4em;
			}
		}

		@include breakpoint(small) {
			padding: 2.5em 1.75em;
		}
	}

	body.is-menu-visible {
		#page-wrapper {
			@include vendor('pointer-events', 'none');
			opacity: 0.5;
		}

		#menu {
			@include vendor('transform', 'translateX(0)');
			visibility: visible;
		}
	}