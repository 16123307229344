///
/// Formula by Pixelarity
/// pixelarity.com | hello@pixelarity.com
/// License: pixelarity.com/license
///

/* Section/Article */

	section, article {
		&.special {
			text-align: center;
		}

		header {
			margin: 0 0 _size(element-margin) 0;

			> :first-child {
				display: inline-block;
				border-bottom: solid 2px;
				margin-bottom: 0.5em;
				padding-bottom: 0.5em;
			}

			> :last-child {
				margin-bottom: 0;
			}

			&.major {
				margin: 0 0 (_size(element-margin) * 1.5) 0;
				text-align: center;
			}
		}

		@include breakpoint(small) {
			header {
				&.major {
					margin: 0 0 _size(element-margin) 0;
				}
			}
		}
	}

	@mixin color-section($p: null) {
		section, article {
			header {
				> :first-child {
					border-bottom-color: _palette($p, border);
				}
			}
		}
	}

	@include color-section;