﻿/*!
	Timeline - v0.0.1
	ilker Yılmaz
	https://github.com/ilkeryilmaz/timeline
 */


// Timeline Variables
// --------------------------------------------------------
$item-width: 960px;
$item-height: 300px;
$transition-time: 1s;
$transition-ease: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$dots-item-width: 35px;


// Timeline Mixins
// --------------------------------------------------------
@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}



// Timeline Structure
// --------------------------------------------------------

.timeline-container {
    position: relative;
    @include clearfix;
}

.timeline {
    width: 100%;
    position:relative;

    .row {
        height: $item-height;
        position:relative;
        >* {
            height: 100%;
        }
    }
    
    .timeline-photo,
    .timeline-text {
        position:relative;
        height:94%;
        border-radius:4px;
        font-size:.8em;
    }

    .timeline-photo {
        background-size:cover;
        background-position:center;

        .license {
            position:absolute;
            bottom:0;
            width:100%;
            height:auto;
            font-size:10px;
            background:rgba(255,255,255,.5);
            color:black;

            p {
                margin-bottom: 0;
            }
        }
    }
}

.timeline-list {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.timeline-list-wrap {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    transform: translate3d(0, 0, 0);
    transition: transform $transition-time $transition-ease;
}

.timeline-item {
    float: left;
    width: $item-width;
    height: $item-height;
    border: 1px solid #ddd;
    box-sizing: border-box;
}


// Timeline Dots
// --------------------------------------------------------
.timeline-dots-wrap {
    position: absolute;
    overflow: hidden;

    .timeline-horizontal & {
        width: 100%;
        height: $dots-item-width;
    }

    .timeline-vertical & {
        width: $dots-item-width + 30px;
        height: 100%;
        top: 0;
    }

    &.bottom {
        bottom: -50px;
    }

    &.top {
        top: -50px;
    }

    &.left {
        left: -65px;
    }

    &.right {
        right: -70px;
    }
}

.timeline-dots {
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform $transition-time $transition-ease;

    .timeline-horizontal & {
        background: url('../img/timeline-bg.png') bottom repeat-x;
    }

    .timeline-vertical & {
        background: url('../img/timeline-vertical-bg.png') left repeat-y;
    }

    li {
        transition: all $transition-time $transition-ease;
        list-style: none;
        overflow: hidden;

        .timeline-horizontal & {
            float: left;
            width: 50px;
            text-align: center;
        }

        .timeline-vertical & {
            width: 100%;
        }

        &.slide-active {
            button {
                color: #2972ca;
                font-size: 15px;
            }
        }
    }

    button {
        cursor: pointer;
        border: none;
        outline: none;
        color: #333;
        font-size: 12px;
        transition: all $transition-time $transition-ease;

        // Resets skel-styling
        box-shadow:none;
        padding:0 0 15px 0;
        height:auto;
        line-height:normal;
        letter-spacing:.15em;

        .timeline-horizontal & {
            background: url('../img/date-icon.png') bottom no-repeat;
            padding-bottom: 15px;
        }

        .timeline-vertical & {
            background: url("../img/date-icon-vertical.png") left no-repeat;
            padding-left: 15px;
        }
    }
}



// Timeline Theme
// --------------------------------------------------------

.timeline-container {
    width: $item-width;
    margin: 0 auto;
    padding-top:50px;
}

.timeline-list {
    width: $item-width;
}

.timeline-item {
    width: $item-width;
    height: $item-height;
    padding: 10px;
    border: 0px solid #ddd;
    background:rgb(224,224,224);
    border-radius:4px;
}
