@import 'vars';
@import '../vendor/px-formula/scss/libs/functions';
@import '../vendor/px-formula/scss/libs/mixins';
@import '../vendor/px-formula/scss/libs/skel';
@import 'colorbox';
@import 'timeline';
@import '../../node_modules/font-awesome/scss/font-awesome.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto:300,500|Oswald:400');

/*
	Formula by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

	@include skel-breakpoints((
		xlarge: '(max-width: 1680px)',
		large: '(max-width: 1280px)',
		medium: '(max-width: 980px)',
		small: '(max-width: 736px)',
		xsmall: '(max-width: 480px)',
		xxsmall: '(max-width: 360px)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border',
		grid: ( gutters: 2em ),
		breakpoints: (
			large: (
				grid: ( gutters: 1.5em )
			),
			small: (
				grid: ( gutters: 1.25em )
			)
		)
	));

	@mixin color($p) {
		@include color-typography($p);
		@include color-box($p);
		@include color-button($p);
		@include color-form($p);
		@include color-list($p);
		@include color-section($p);
		@include color-table($p);
		@include color-spotlight($p);
		@include color-features($p);
	}

// Base.

	@import '../vendor/px-formula/scss/base/page';
	@import '../vendor/px-formula/scss/base/typography';

// Component.

	@import '../vendor/px-formula/scss/components/box';
	@import '../vendor/px-formula/scss/components/button';
	@import '../vendor/px-formula/scss/components/form';
	@import '../vendor/px-formula/scss/components/icon';
	@import '../vendor/px-formula/scss/components/image';
	@import '../vendor/px-formula/scss/components/list';
	@import '../vendor/px-formula/scss/components/section';
	@import '../vendor/px-formula/scss/components/table';
	@import '../vendor/px-formula/scss/components/spotlight';
	@import '../vendor/px-formula/scss/components/features';

// Layout.

	@import '../vendor/px-formula/scss/layout/header';
	@import '../vendor/px-formula/scss/layout/menu';
	@import '../vendor/px-formula/scss/layout/banner';
	@import '../vendor/px-formula/scss/layout/cta';
	@import '../vendor/px-formula/scss/layout/footer';
	@import '../vendor/px-formula/scss/layout/wrapper';
	@import '../vendor/px-formula/scss/layout/page-wrapper';

.logo-wrapper {
  background:darkorange;
  height:100%;
  padding:11px;
}

/*
ul.links > li > ul {
    display:none;
}
ul.links > li.active > ul {
    display:block;
    list-style-type:square;
}
*/
ul.links > li > ul {
    list-style-type:square;
}

/*  FORM  */
.g-recaptcha {
  max-width:270px;
  transform: scale(0.9);
  transform-origin: 0 0;
}
.statusHide{ display:none;}
.field-validation-valid{ display: none; }
.validation-summary-valid{ display: none;}
.control-label{margin-bottom:0px;text-align:left;}
.form-group{margin-bottom:1em;text-align:left;}
.text-danger{color:red;}
.form-control{width:100%;}

 .alert-danger{    
    padding: 20px;
    background-color: #f44336; /* Red */
    color: white;
    margin-bottom: 15px; 

}

 .alert-success{    
    padding: 20px;
    background-color: #4CAF50;
    color: white;
    margin-bottom: 15px; 
    border-radius:4px;
}


 #status{
     border-radius:4px;
 }

 .closebtn {
    color: white;
    font-weight: bold;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
    padding-top:-20px;
    float:right;
}


/*  STARTPAGE SECTIONS */

#wrapper {

    > .sectionDark { 
        background:rgba(0,0,0,.8);
        border-bottom:none;

        * {
            color:rgba(255,255,255,.6);
        }

        h2 {
            color:darkorange;
            border-bottom-color:orangered !important;
        }
    }

    > .sectionMedium { 
        background:rgba(0,0,0,.7);
        border-bottom:none;
        * {
            color:rgba(255,255,255,.6);
        }

        h2 {
            color:darkorange;
            border-bottom-color:orangered !important;
        }
    }
    > .sectionLight { 
        background:rgba(0,0,0,.5);
        border-bottom:none;
        * {
            color:rgba(255,255,255,.6);
        }

        h2 {
            color:darkorange;
            border-bottom-color:orangered !important;
        }
    }
}


.intro {
    font-size:1.3em;
}

.perlin-event{
    color:black;
}

.img-responsive img, img.img-responsive {
    display: block;
    max-width: 100%;
    height: auto !important;
}
